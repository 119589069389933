<template>
    <div class="flex justify-content-center flex-wrap align-items-center">
        <div class="md-2 mr-2">
            <div>
                <h1>Hi I'm Callum</h1>
                <p>A Software Developer from Coventry</p>
            </div>
        </div>
        <div class="md-2 mr-2">
            <Image alt="me" src="header.png" />
        </div>
    </div>
</template>
